import api from "./interceptor"

export const getAllUsers = async () => {
    try {
        const response = await api.get(`/users/v1/get_users`, {
            params: { company_name: localStorage.getItem('company_name') }
        });
        return response;
    } catch (error) {
        throw error;
    }
}

export const AddNewUserData = async (user_data) => {
    try {
        const company_name = localStorage.getItem('company_name');
        const response = await api.post(`/users/v1/add_users`, user_data, {params: { company_name: company_name }});
        return response;
    } catch (error) {
        throw error;
    }
}

export const updateUserData = async (user_id, user_data) => {
    try {
        const company_name = localStorage.getItem('company_name');
        const response = await api.post(`/users/v1/update_users?company_name=${company_name}&user_id=${user_id}`, user_data);
        return response;
    } catch (error) {
        throw error;
    }
}

export const deleteUser = async (user_id) => {
    try {
        const company_name = localStorage.getItem('company_name');
        const response = await api.post(`/users/v1/delete_users?company_name=${company_name}&user_id=${user_id}`);
        return response;
    } catch (error) {
        throw error;
    }
}