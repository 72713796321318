import { AnimatePresence, motion } from "framer-motion";
import React, { useState, useEffect } from "react";
import { FaAngleDown } from "react-icons/fa";
import { NavLink } from "react-router-dom";

const SidebarMenu = ({ route, isOpen, setIsOpen }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    setIsOpen(true);
  };

  useEffect(() => {
    if (!isOpen) {
      setIsMenuOpen(false);
    }
  }, [isOpen]);
  return (
    <>
      <div className="menu" onClick={toggleMenu}>
        <div className="menu_item">
          {/* <div className="icon">{route.icon}</div> */}
          {isOpen && (
            <div>{route.name}</div>
          )}
        </div>
        {isOpen && (
          <div
            animate={
              isMenuOpen
                ? {
                  rotate: -90,
                }
                : { rotate: 0 }
            }
          >
            <FaAngleDown />
          </div>
        )}
      </div>{" "}
        {isMenuOpen && (
          <div>
            {route.subRoutes.map((subRoute, i) => (
              <div key={i} custom={i}>
                <NavLink to={subRoute.path} className="link">
                  {/* <div className="icon">{subRoute.icon}</div> */}
                  <div className="link_text">{subRoute.name}</div>
                </NavLink>
              </div>
            ))}
          </div>
        )}{" "}
    </>
  );
};

export default SidebarMenu;
