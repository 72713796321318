import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { getCompanyList } from '../utils/api.common';
import { useCompanyContext } from '../common/companyContext';
import { Box } from '@mui/material';
import moment from 'moment/moment';

function Header() {
    const { updateSelectedCompany } = useCompanyContext();
    const [selected_company_name, set_selected_company_name] = React.useState('');
    const [company_list, set_company_list] = React.useState([])

    React.useEffect(() => {
        (async () => {
            getCompanyData()
        })()
    }, [])

    const getCompanyData = async () => {
        try {
            const response = await getCompanyList()
            response?.data?.data && set_company_list(response?.data?.data)
            const company_name = localStorage.getItem('company_name')
            if (company_name) {
                updateSelectedCompany(company_name)
                set_selected_company_name(company_name)
            }
        } catch (error) {
            console.error(error);
        }
    }

    const onHandleCompanyChange = (event) => {
        if (event.target.value) {
            localStorage.setItem('company_name', event.target.value)
            updateSelectedCompany(event.target.value);
            set_selected_company_name(event.target.value);
        }
    };

    const getCapitalizeName = (company) => {
        return company.charAt(0).toUpperCase() + company.slice(1)
    }

    return (
        <div>
            <header style={{ padding: 30 }}>
                <div className='d-flex justify-between'>
                    <Box className='d-flex justify-start flex-direction-column'>
                        <p className='dark-blue fs-22 fw-bold'>Welcome <span>{getCapitalizeName(selected_company_name)}!</span></p>
                        <p className='black m-t-10'>Here what happen at {getCapitalizeName(selected_company_name)}: Today {moment(new Date()).format('DD/MM/YYYY')}</p>
                    </Box>
                    <FormControl sx={{ m: 1, minWidth: '50%' }} size="small">
                        <InputLabel>Company</InputLabel>
                        <Select
                            value={selected_company_name}
                            label="Company"
                            onChange={onHandleCompanyChange}
                        >
                            {company_list && company_list.map((company, index) => (
                                <MenuItem key={index} value={company}>{getCapitalizeName(company)}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            </header>
        </div>
    )
}

export default Header