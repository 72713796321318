import { Box, Button, IconButton, Modal } from '@mui/material'
import React, { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    bgcolor: 'background.paper',
    border: '2px solid aliceblue',
    borderRadius: 3,
    boxShadow: 24,
    p: 4,
    backgroundColor: '#e9f0f7 !important'
};

function ViewReport({ open, setOpen, process_type }) {
    const navigate = useNavigate()
    const [start_date, setStartDate] = useState()
    const [end_date, setEndDate] = useState()
    const [report_type, setReportType] = useState('Log')

    const handleClose = () => {
        setOpen(!open)
        setStartDate()
        setEndDate()
    }

    const onHandleShowReport = () => {
        if (process_type === 'Missing Box') {
            const query_data = {
                start_date: moment(start_date).format('DD/MM/YYYY'),
                end_date: moment(end_date).format('DD/MM/YYYY'),
            }
            navigate(`/dashboard/alert/`, {
                state: query_data
            })
        } else {
            const query_data = {
                process_name: process_type,
                report_name: `${process_type} ${report_type}`,
                start_date: moment(start_date).format('DD/MM/YYYY'),
                end_date: moment(end_date).format('DD/MM/YYYY'),
                is_home_report_route_back: false
            }
            const path_name = `${process_type.toLowerCase()}_${report_type.toLowerCase()}`
            navigate(`/dashboard/report/${path_name}`, {
                state: query_data
            })
        }
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <div className='d-flex justify-between'>
                        <h3 className="APP-color">{process_type} Report</h3>
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                                position: 'absolute',
                                top: 8,
                                right: 8,
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <Box className='d-flex justify-between g-5 m-t-15'>
                        <div className='w-50'>
                            <label className='gray'>Start Date</label>
                            <input
                                type="date"
                                value={start_date}
                                onChange={(e) => setStartDate(e.target.value)}
                                className='w-100 m-t-10 p-16 b-r-10 APP-background'
                                placeholder='Start Date'
                                max={new Date().toISOString().split('T')[0]}
                            />
                        </div>
                        <div className='w-50'>
                            <label className='gray'>End Date</label>
                            <input
                                type="date"
                                value={end_date}
                                onChange={(e) => setEndDate(e.target.value)}
                                className='w-100 m-t-10 p-16 b-r-10 APP-background'
                                placeholder='End Date'
                                min={start_date}
                                max={new Date().toISOString().split('T')[0]}
                            />
                        </div>
                    </Box>

                    {process_type !== 'Missing Box' && <Box>
                        <FormControl className='m-t-10 w-100'>
                            <label className='gray'>Report Type</label>
                            <RadioGroup
                                row
                                defaultValue="Log"
                                color="success"
                                className='m-t-10'
                                onChange={(e) => setReportType(e.target.value)}
                                style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}
                            >
                                <FormControlLabel
                                    value="Log"
                                    control={<Radio color='success' />}
                                    label={<p>{process_type} Log</p>}
                                    className={`w-49 m-0 p-t-8 p-b-8 p-r-10 p-l-10 ${report_type === 'Log' ? 'APP-background app-border' : "border"}`}
                                    style={{ borderRadius: '5px 15px' }}
                                />
                                <FormControlLabel
                                    value="Summary"
                                    control={<Radio color='success' />}
                                    label={<p>{process_type} Summary</p>}
                                    className={`w-49 m-0 p-t-8 p-b-8 p-r-10 p-l-10 ${report_type === 'Summary' ? 'APP-background app-border' : 'border'}`}
                                    style={{ borderRadius: '5px 15px' }}
                                />
                            </RadioGroup>
                        </FormControl>
                    </Box>}

                    <Box className='d-flex justify-end g-5 m-t-10'>
                        <Button
                            className={`m-t-10 ${(start_date && end_date && report_type) ? 'white bg-blue' : ''}`}
                            variant="contained"
                            onClick={onHandleShowReport}
                            disabled={!(start_date && end_date && report_type) ? true : false}
                        >View Report</Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    )
}

export default ViewReport