import { useEffect, useState } from 'react';
import { Box, Button, IconButton, Modal, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Autocomplete from '@mui/material/Autocomplete';
import { getAllDriver } from '../../utils/api.driver';
import { getProductList } from '../../utils/api.common';
import { errorMessage, successMessage } from '../../common/Toast';
import { addNewDeliveryChallan } from '../../utils/api.delivery.challan';
import dayjs from 'dayjs';
import AddIcon from '@mui/icons-material/Add';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    border: '2px solid aliceblue',
    boxShadow: 24,
    borderRadius: 2,
    p: 4,
};

function AddDeliveryChallan({ open_model, setOpenModel, setSuccessResponse }) {
    const [drivers_data, setDriversData] = useState([]);
    const [product_data, setProductData] = useState([]);

    const [challan_id, setChallanId] = useState('');
    const [product_name, setProductName] = useState('');
    const [sku_size, setSkuSize] = useState('');
    const [box_count, setBoxCount] = useState(0);
    const [driver_id, setDriversId] = useState('');
    const [vehicle_no, setVehicleNo] = useState('');
    const [dateTime, setDateTime] = useState(dayjs());
    const [product_varients_box_count, setProductVarientsBoxCount] = useState([]);

    useEffect(() => {
        (async () => {
            try {
                if (open_model) {
                    const [driver_response, product_response] = await Promise.all([
                        getAllDriver(),
                        getProductList(),
                    ])
                    if (driver_response?.status === 200 && product_response?.status === 200) {
                        setDriversData(driver_response?.data?.data);
                        setProductData(product_response?.data?.data);
                    }
                }
            } catch (error) {
                errorMessage('Error: Fetching data failed!')
            }
        })()
    }, [open_model])

    const handleClose = () => {
        setOpenModel(false);
        clear();
    };

    const onHandleDriverData = (event, new_value) => {
        if (new_value) {
            setDriversId(new_value?.id);
            setVehicleNo(new_value?.vehicle_no);
        }
    };

    const onHandleProductData = (event, newValue) => {
        if (newValue) {
            setProductName(newValue?.product_name);
            setSkuSize(newValue?.sku_size);
        }
    };

    const onAddProductVarients = () => {
        if (!product_name || !sku_size || !box_count) {
            errorMessage('Please fill all the fields')
        } else {
            const alreadyExistIndex = product_varients_box_count.findIndex(item => item.product_name === product_name && item.sku_size === sku_size)
            if (alreadyExistIndex !== -1) {
                const updatedVariants = [...product_varients_box_count];
                updatedVariants[alreadyExistIndex].box_count = +box_count;
                setProductVarientsBoxCount(updatedVariants);
            } else {
                setProductVarientsBoxCount([...product_varients_box_count, {
                    product_name: product_name, sku_size: sku_size, box_count: +box_count
                }])
            }
            setProductName('');
            setSkuSize('');
            setBoxCount(0);
        }
    }

    const onRemoveProductVarients = (index) => {
        setProductVarientsBoxCount(product_varients_box_count.filter((_, i) => i !== index))
    }

    const clear = () => {
        setProductName('');
        setSkuSize('');
        setBoxCount(0);
        setDriversId('');
        setVehicleNo('');
        setDateTime(dayjs());
        setChallanId('');
        setProductVarientsBoxCount([]);
    }

    const onHandleSubmit = async () => {
        try {
            const payload = {
                challan_id: challan_id,
                driver_id: driver_id,
                vehicle_no: vehicle_no,
                products: product_varients_box_count,
                date: dateTime.format('YYYY-MM-DDTHH:mm:ss.000Z')
            }
            const response = await addNewDeliveryChallan(payload);
            if (response?.status === 200) {
                successMessage(response?.data?.message)
                setSuccessResponse(true)
                handleClose()
            }
        } catch (error) {
            errorMessage(error?.response?.data?.error)
        }
    }

    return (
        <div>
            <Modal
                open={open_model}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <Box className='d-flex justify-between w-100 bg-red'>
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                                position: 'absolute',
                                top: 8,
                                right: 8,
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" component="h2">
                            <strong>Add Delivery Challan</strong>
                        </Typography>
                    </Box>
                    <Box className='w-100'>
                        <Box className='m-t-20 d-flex justify-between g-5'>
                            <Box className="w-65">
                                <TextField
                                    label="Challan No"
                                    fullWidth
                                    value={challan_id}
                                    onChange={(e) => setChallanId(e.target.value)}
                                />
                            </Box>
                            <Box className="w-35">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Select Date"
                                        value={dateTime}
                                        onChange={(e) => setDateTime(e)}
                                        fullWidth
                                    />
                                </LocalizationProvider>
                            </Box>
                        </Box>
                        <Box className='m-t-10'>
                            <Autocomplete
                                disablePortal
                                options={drivers_data}
                                getOptionLabel={(option) => `${option.vehicle_no}  (${option.name.trim()})`}
                                renderInput={(params) => (
                                    <TextField
                                        width="100%"
                                        {...params}
                                        label="Vehicle & Driver Name"
                                    />
                                )}
                                onChange={onHandleDriverData}
                            />
                        </Box>
                        <Box className='m-t-10'>
                            {product_varients_box_count.length > 0 && <Table className='bg-light-gray b-5'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className='m-0 p-0 p-l-15 p-5 dark-blue'><strong>Product Name</strong></TableCell>
                                        <TableCell className='m-0 p-0 p-l-15 dark-blue'><strong>Sku Size</strong></TableCell>
                                        <TableCell className='m-0 p-0 p-l-15 dark-blue'><strong>Box Count</strong></TableCell>
                                        <TableCell className='m-0 p-0 p-l-15 dark-blue'><strong>Action</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {product_varients_box_count?.map((item, index) => (
                                        <TableRow>
                                            <TableCell className='m-0 p-0 p-l-15 p-5'>{item.product_name}</TableCell>
                                            <TableCell className='m-0 p-0 p-l-15'>{item.sku_size}</TableCell>
                                            <TableCell className='m-0 p-0 p-l-15'>{item.box_count}</TableCell>
                                            <TableCell className='m-0 p-0 p-l-15' onClick={() => onRemoveProductVarients(index)}><p className='cursor red'>X</p></TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>}
                        </Box>
                        <Box className='m-t-20'>
                            <Autocomplete
                                disablePortal
                                value={{ product_name, sku_size }}
                                options={product_data}
                                getOptionLabel={(option) => `${option.product_name && `${option.product_name}`} ${option.sku_size && `(${option.sku_size})`}`}
                                renderInput={(params) => (
                                    <TextField
                                        width="100%"
                                        {...params}
                                        label="Product Name & SKU Size"
                                    />
                                )}
                                onChange={onHandleProductData}
                            />
                        </Box>
                        <Box className='m-t-20 w-100 d-flex g-3'>
                            <TextField
                                type='number'
                                label="Box Count"
                                fullWidth
                                value={box_count ? box_count : ''}
                                onChange={(e) => setBoxCount(e.target.value)}
                            />
                            <Button className='border b-5' onClick={onAddProductVarients}><AddIcon /></Button>

                        </Box>
                        <Box className='m-t-20 w-100 d-flex justify-end g-5'>
                            <Button type='submit' className='APP-background p-r-10 p-l-10' onClick={onHandleSubmit}>Submit</Button>
                            <Button  className='APP-background p-r-10 p-l-10' onClick={handleClose}>Cancel</Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </div >
    )
}

export default AddDeliveryChallan