import { Box, Card, IconButton, LinearProgress, Modal } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { getImageUrl } from '../../utils/api.report';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '30%',
    maxWidth: '90%',
    height: '75%',
    bgcolor: 'background.paper',
    // border: '2px solid aliceblue',
    borderRadius: 3,
    boxShadow: 24,
    backgroundColor: '#e9f0f7 !important',
    margin: 0,

};

function ImageViewer({ open, setOpen, images_list }) {
    const [image_urls, setImageUrls] = useState([])
    const [is_loading, setIsLoading] = useState(false)
    useEffect(() => {
        (async () => {
            if (open) {
                try {
                    for (const image of images_list) {
                        console.log(image);
                        const response = await getImageUrl(image);
                        if (response?.data?.data?.image_url) {
                            setImageUrls((prev) => [...prev, {
                                image_url: response.data.data.image_url,
                                image_name: image
                            }]);
                        }
                    }
                } catch (error) {
                    console.log(error);
                } finally {
                    setIsLoading(true)
                }
            }
        })()
    }, [images_list, open])

    const handleClose = () => {
        setOpen(!open)
        setImageUrls([])
        setIsLoading(false)
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Card sx={style}>
                    {!is_loading && <LinearProgress sx={{
                        '& .MuiLinearProgress-bar': {
                            backgroundColor: 'rgb(2, 2, 77)',
                            transition: 'none'
                        },
                    }} />}

                    <Box className='p-t-10 p-r-15 p-l-15'>
                        <div className='d-flex justify-between'>
                            <h3 className="APP-color">Image View</h3>
                            <IconButton
                                aria-label="close"
                                onClick={handleClose}
                                className="APP-color"
                                sx={{
                                    position: 'absolute',
                                    top: 8,
                                    right: 8,
                                }}
                            >

                                <CloseIcon />
                            </IconButton>
                        </div>
                        <Box className='d-flex justify-between align-center g-5 m-t-15'>
                            {image_urls && image_urls.map((image, index) => (
                                <Box key={index}>
                                    <img
                                        src={image.image_url}
                                        alt="front_image"
                                        style={{ borderRadius: 10, marginTop: 0 }}
                                        height={450}
                                        width={450}
                                    />
                                    <p className='w-100 text-center'>{image.image_name}</p>
                                </Box>
                            ))}
                            {!image_urls.length && <Box className="image-not-found">
                                <img
                                    src='https://daluscapital.com/wp-content/uploads/2016/04/dummy-post-square-1-1.jpg'
                                    alt="front_image"
                                    className="image"
                                />
                                <div className="image-not-found-text">
                                    <p>Image not found</p>
                                </div>
                            </Box>}
                        </Box>
                    </Box>
                </Card>
            </Modal>
        </div>
    )
}

export default ImageViewer