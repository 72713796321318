import { IST_date_time, ShowIndianStandardTime } from "../common/common";

export const productionLogColumn = (onHandleImages, setIsShowImageViewer, total_row_count) => {
    const handleClick = (images) => {
        onHandleImages(images);
        setIsShowImageViewer(true);
    };
    return [
        {
            headerName: "S No.",
            valueGetter: params => total_row_count ? total_row_count - params.node.rowIndex : params.node.rowIndex + 1,
            width: 78,
            sortable: true
        },
        {
            headerName: 'Date',
            width: 120,
            field: 'detection_time',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true,
            cellRenderer: function (params) {
                return (
                    <p>{IST_date_time(params.value).date}</p>
                );
            },
        },
        {
            headerName: 'Time',
            width: 100,
            field: 'detection_time',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true,
            cellRenderer: function (params) {
                return (
                    <p>{IST_date_time(params.value).time}</p>
                );
            },
        },
        {
            headerName: 'Shift',
            width: 100,
            field: 'shift',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'Line No',
            width: 100,
            field: 'line_no',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'User Name',
            width: 160,
            field: 'user_name',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'Product Name',
            width: 300,
            field: 'product_name',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'SKU Size',
            width: 220,
            field: 'net_weight',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'SAP Code',
            width: 150,
            field: 'sap_code',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'Weight',
            width: 100,
            field: 'weight',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'Box Count',
            width: 115,
            field: 'box_count',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'Images',
            width: 150,
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            cellRenderer: function (params) {
                const { front_box_image, side_box_image, back_box_image } = params.data;
                const images = [front_box_image, side_box_image, back_box_image];
                return (
                    <p className='cursor' style={{ color: 'blue' }} onClick={() => handleClick(images)}
                    >View Images</p>
                );
            }
        },
    ]
}

export const productionSummaryColumn = () => {
    return [
        {
            headerName: "S No.",
            valueGetter: "node.rowIndex + 1",
            width: 100,
            sortable: true
        },
        {
            headerName: 'Date',
            field: 'detection_date',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true,
            cellRenderer: function (params) {
                return (
                    <p>{IST_date_time(params.value).date}</p>
                );
            },
        },
        {
            headerName: 'Shift',
            width: 165,
            field: 'shift',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'Line No',
            width: 165,
            field: 'line_no',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'Product Name',
            width: 300,
            field: 'product_name',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'SKU Size',
            width: 220,
            field: 'net_weight',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'Total Weight',
            field: 'total_weight',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'Total Box Count',
            field: 'total_box_count',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
    ]
}

export const warehouseLogColumn = () => {
    return [
        {
            headerName: "S No.",
            valueGetter: "node.rowIndex + 1",
            width: 78,
            sortable: true
        },
        {
            headerName: 'Date',
            width: 120,
            field: 'detection_time',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true,
            cellRenderer: function (params) {
                return (
                    <p>{IST_date_time(params.value).date}</p>
                );
            },
        },
        {
            headerName: 'Time',
            width: 100,
            field: 'detection_time',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true,
            cellRenderer: function (params) {
                return (
                    <p>{IST_date_time(params.value).time}</p>
                );
            },
        },
        {
            headerName: 'Shift',
            width: 100,
            field: 'shift',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'Line No',
            width: 100,
            field: 'line_no',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'User Name',
            width: 160,
            field: 'user_name',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'Product Name',
            width: 300,
            field: 'product_name',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'SKU Size',
            width: 220,
            field: 'net_weight',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'SAP Code',
            width: 150,
            field: 'sap_code',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'Weight',
            width: 100,
            field: 'weight',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'Box Count',
            width: 115,
            field: 'box_count',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        }
    ]
}

export const warehouseLogColumnWithQRcode = (onHandleImages, setIsShowImageViewer) => {
    const handleClick = (images) => {
        onHandleImages(images);
        setIsShowImageViewer(true);
    };
    return [
        {
            headerName: "S No.",
            valueGetter: "node.rowIndex + 1",
            width: 78,
            sortable: true
        },
        {
            headerName: 'Date',
            width: 120,
            field: 'detection_time',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true,
            cellRenderer: function (params) {
                return (
                    <p>{IST_date_time(params.value).date}</p>
                );
            },
        },
        {
            headerName: 'Time',
            width: 100,
            field: 'detection_time',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true,
            cellRenderer: function (params) {
                return (
                    <p>{IST_date_time(params.value).time}</p>
                );
            },
        },
        {
            headerName: 'Shift',
            width: 100,
            field: 'shift',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'Line No',
            width: 100,
            field: 'line_no',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'User Name',
            width: 160,
            field: 'user_name',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'Product Name',
            width: 300,
            field: 'product_name',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'SKU Size',
            width: 220,
            field: 'net_weight',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'SAP Code',
            width: 150,
            field: 'sap_code',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'Weight',
            width: 100,
            field: 'weight',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'Box Count',
            width: 115,
            field: 'box_count',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'QR Image',
            width: 150,
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            cellRenderer: function (params) {
                const { qr_code_image } = params.data;
                const images = [qr_code_image];
                return (
                    <p className='cursor' style={{ color: 'blue' }} onClick={() => handleClick(images)}
                    >View Images</p>
                );
            }
        },
        {
            headerName: 'Batch Number',
            width: 200,
            field: 'batch_number',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'Date Of Manufacture',
            width: 200,
            field: 'date_of_manufacture',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true,
            cellRenderer: function (params) {
                return (
                    <p>{IST_date_time(params.value).date}</p>
                );
            },
        },
        {
            headerName: 'Date Of Expiry',
            width: 200,
            field: 'date_of_expiry',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true,
            cellRenderer: function (params) {
                return (
                    <p>{IST_date_time(params.value).date}</p>
                );
            },
        },

    ]
}

export const warehouseSummaryColumn = () => {
    return [
        {
            headerName: "S No.",
            valueGetter: "node.rowIndex + 1",
            width: 78,
            sortable: true
        },
        {
            headerName: 'Date',
            width: 120,
            field: 'detection_date',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true,
            cellRenderer: function (params) {
                return (
                    <p>{IST_date_time(params.value).date}</p>
                );
            },
        },
        {
            headerName: 'Shift',
            width: 115,
            field: 'shift',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'Line No',
            width: 115,
            field: 'line_no',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'Product Name',
            width: 300,
            field: 'product_name',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'Total Weight',
            field: 'total_weight',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'Total Box Count',
            field: 'total_box_count',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'Total Avarage Weight',
            field: 'avg_weight',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
    ]
}

export const dispatchLogColumn = (onHandleImages, setIsShowImageViewer) => {
    const handleClick = (images) => {
        onHandleImages(images);
        setIsShowImageViewer(true);
    };
    return [
        {
            headerName: 'Date',
            width: 120,
            field: 'detection_time',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true,
            cellRenderer: function (params) {
                return (
                    <p>{IST_date_time(params.value).date}</p>
                );
            },
        },
        {
            headerName: 'Time',
            width: 100,
            field: 'detection_time',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true,
            cellRenderer: function (params) {
                return (
                    <p>{IST_date_time(params.value).time}</p>
                );
            },
        },
        {
            headerName: 'Shift',
            width: 100,
            field: 'shift',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'Line No',
            width: 100,
            field: 'line_no',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'User Name',
            width: 160,
            field: 'user_name',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'Driver Name',
            width: 160,
            field: 'driver_name',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'Vehicle No',
            width: 160,
            field: 'vehicle_no',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'Product Name',
            width: 300,
            field: 'product_name',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'SKU Size',
            width: 220,
            field: 'net_weight',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'Weight',
            width: 100,
            field: 'weight',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true,
            cellStyle: params => {
                if (params.data.weight_status === 'good') {
                    return { color: 'white', backgroundColor: 'green' };
                } else if (params.data.weight_status === 'over_weighted') {
                    return { color: 'white', backgroundColor: 'orange' }
                } else if (params.data.weight_status === 'under_weighted') {
                    return { color: 'white', backgroundColor: 'red' }
                } else {
                    return null;
                }
            }
        },
        {
            headerName: 'Box Count',
            width: 115,
            field: 'box_count',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'Images',
            width: 150,
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            cellRenderer: function (params) {
                const { side_box_image } = params.data;
                const images = [side_box_image];
                return (
                    <p className='cursor' style={{ color: 'blue' }} onClick={() => handleClick(images)}
                    >View Images</p>
                );
            }
        },
    ]
}

export const dispatchSummaryColumn = () => {
    return [
        {
            headerName: 'Date',
            width: 120,
            field: 'detection_date',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true,
            cellRenderer: function (params) {
                return (
                    <p>{IST_date_time(params.value).date}</p>
                );
            },
        },
        {
            headerName: 'Shift',
            width: 100,
            field: 'shift',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'Line No',
            width: 100,
            field: 'line_no',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'Vehicle No',
            width: 160,
            field: 'vehicle_no',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'Product Name',
            width: 300,
            field: 'product_name',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'Total Box Count',
            field: 'total_box_count',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'Total Weight',
            field: 'total_weight',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'Total Avarage Weight',
            field: 'avg_weight',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
    ]
}

export const verificationReportColumn = () => {
    return [
        {
            headerName: "S No.",
            valueGetter: "node.rowIndex + 1",
            width: 85,
            sortable: true
        },
        {
            headerName: 'Date',
            width: 125,
            field: 'date',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true,
            cellRenderer: function (params) {
                return (
                    <p>{ShowIndianStandardTime(params.value).date}</p>
                );
            },
        },
        {
            headerName: 'Time',
            width: 110,
            field: 'date',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true,
            cellRenderer: function (params) {
                return (
                    <p>{ShowIndianStandardTime(params.value).time}</p>
                );
            },
        },
        {
            headerName: 'Challan ID',
            width: 200,
            field: 'challan_id',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'Vehicle Number',
            width: 200,
            field: 'vehicle_no',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'Driver Name',
            width: 220,
            field: 'name',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'Phone Number',
            width: 220,
            field: 'phone_no',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'Status',
            width: 220,
            field: 'status',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
    ]
}

export const alertMissingColumn = () => {
    return [
        {
            headerName: 'Date',
            width: 120,
            field: 'detection_time',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true,
            cellRenderer: function (params) {
                return (
                    <p>{IST_date_time(params.value).date}</p>
                );
            },
        },
        {
            headerName: 'Time',
            width: 100,
            field: 'detection_time',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true,
            cellRenderer: function (params) {
                return (
                    <p>{IST_date_time(params.value).time}</p>
                );
            },
        },
        {
            headerName: 'Shift',
            width: 100,
            field: 'shift',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'Line No',
            width: 100,
            field: 'line_no',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'User Name',
            width: 160,
            field: 'user_name',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'Product Name',
            width: 300,
            field: 'product_name',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'SKU Size',
            width: 220,
            field: 'net_weight',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'SAP Code',
            width: 150,
            field: 'sap_code',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'Weight',
            width: 100,
            field: 'weight',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'Box Count',
            width: 115,
            field: 'box_count',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        }
    ]
}

export const deliveryChallanColumn = () => {
    return [
        {
            headerName: "S No.",
            valueGetter: "node.rowIndex + 1",
            width: 85,
            sortable: true
        },
        {
            headerName: 'Date',
            width: 125,
            field: 'date',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true,
            cellRenderer: function (params) {
                return (
                    <p>{ShowIndianStandardTime(params.value).date}</p>
                );
            },
        },
        {
            headerName: 'Time',
            width: 110,
            field: 'date',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true,
            cellRenderer: function (params) {
                return (
                    <p>{ShowIndianStandardTime(params.value).time}</p>
                );
            },
        },
        {
            headerName: 'Challan ID',
            width: 200,
            field: 'challan_id',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'Vehicle Number',
            width: 200,
            field: 'vehicle_no',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'Products Count',
            field: 'total_products_count',
            width: 150,
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'Driver Name',
            width: 220,
            field: 'name',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'Phone Number',
            width: 220,
            field: 'phone_no',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
    ]
}

export const trackerColumn = () => {
    return [
        {
            headerName: "S No.",
            valueGetter: "node.rowIndex + 1",
            width: 78,
            sortable: true
        },
        {
            headerName: 'Company Name',
            width: 300,
            field: 'company_name',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'Status',
            field: 'status',
            width: 300,
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'Notification',
            width: 280,
            field: 'notification',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        },
        {
            headerName: 'Product Name',
            field: 'product_name',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true
        }
    ]
}
