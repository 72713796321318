import api from './interceptor'

export const getTodayTotalBoxCount = async () => {
    try {
        const company_name = localStorage.getItem('company_name');
        const response = await api.get(`/dashboard/v1/today_total_box_count`, {params: { company_name: company_name }});
        return response;
    } catch (error) {
        throw error;
    }
};

export const getCompanyList = async () => {
    try {
        const response = await api.get(`/dashboard/v1/get_child_company`);
        return response;
    } catch (error) {
        throw error;
    }
};

export const addCompany = async (data) => {
    try {
        const company_name = localStorage.getItem('company_name');
        const response = await api.post(`/dashboard/v1/add_company`, data, {params: {company_name: company_name}});
        return response;
    } catch (error) {
        return error;
    }
};

export const getLineList = async () => {
    try {
        const company_name = localStorage.getItem('company_name');
        const response = await api.get(`/dashboard/v1/get_lines`, {params: {company_name: company_name}});
        return response;
    } catch (error) {
        throw error;
    }
};

export const addLine = async (line_data) => {
    try {
        const company_name = localStorage.getItem('company_name');
        const response = await api.post(`/dashboard/v1/addLine`, line_data, {params: {company_name: company_name}});
        return response;
    } catch (error) {
        throw error;
    }
};

export const deleteLine = async (line_id) => {
    try {
        const company_name = localStorage.getItem('company_name');
        const response = await api.post(`/dashboard/v1/delete_line?company_name=${company_name}&line_id=${line_id}`);
        return response;
    } catch (error) {
        throw error;
    }
};

export const getShiftList = async () => {
    try {
        const company_name = localStorage.getItem('company_name');
        const response = await api.get(`/dashboard/v1/get_shifts`, {params: {company_name: company_name}});
        return response;
    } catch (error) {
        throw error;
    }
};

export const addShift = async (line_data) => {
    try {
        const company_name = localStorage.getItem('company_name');
        const response = await api.post(`/dashboard/v1/addShift`, line_data, {params: {company_name: company_name}});
        return response;
    } catch (error) {
        throw error;
    }
};

export const deleteShift = async (shift_id) => {
    try {
        const company_name = localStorage.getItem('company_name');
        const response = await api.post(`/dashboard/v1/delete_shift?company_name=${company_name}&shift_id=${shift_id}`);
        return response;
    } catch (error) {
        throw error;
    }
};

export const getProductList = async () => {
    try {
        const company_name = localStorage.getItem('company_name');
        const response = await api.get(`/dashboard/v1/get_products`, {params: {company_name: company_name}});
        return response;
    } catch (error) {
        throw error;
    }
};

export const addProduct = async (product_data) => {
    try {
        const company_name = localStorage.getItem('company_name');
        const response = await api.post(`/dashboard/v1/add_product`, product_data, {params: {company_name: company_name}});
        return response;
    } catch (error) {
        throw error;
    }
};

export const deleteProduct = async (product_id) => {
    try {
        const company_name = localStorage.getItem('company_name');
        const response = await api.post(`/dashboard/v1/delete_product?company_name=${company_name}&product_id=${product_id}`);
        return response;
    } catch (error) {
        throw error;
    }
};