import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { useEffect, useState } from 'react';
import { getTodayTotalBoxCount } from '../../utils/api.common';
import { errorMessage } from '../../common/Toast';
import { useCompanyContext } from '../../common/companyContext';
import ViewReport from './ViewReport';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#e9f0f7',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  boxShadow: 'rgb(2, 2, 77) 0px 3px 6px -2px, rgba(0, 0, 0, 0.3) 0px 3px 3px -3px',
  margin: '0px 10px',
}));

function Home() {
  const { selectedCompany } = useCompanyContext();
  const [is_open_view_report, setIsOpenViewReport] = useState(false);
  const [process_type, setProcessType] = useState('');
  const [today_box_count_data, setTodayBoxCountData] = useState({
    total_box_count_at_production_room: 0,
    total_box_count_at_warehouse_room: 0,
    total_box_difference: 0,
    total_box_count_at_dispatch_room: 0
  });

  useEffect(() => {
    (async () => {
      try {
        const today_data = await getTodayTotalBoxCount();
        if (today_data?.data?.data) {
          setTodayBoxCountData({
            ...today_box_count_data,
            total_box_count_at_production_room: today_data?.data?.data?.production_box_count,
            total_box_count_at_warehouse_room: today_data?.data?.data?.warehouse_box_count,
            total_box_difference: today_data?.data?.data?.total_difference,
            total_box_count_at_dispatch_room: today_data?.data?.data?.dispatch_box_count
          })
        }
      } catch (error) {
        console.error(error);
        errorMessage(error?.response?.data?.message);
      }
    })()
  }, [selectedCompany])

  const onHandleCardClick = (type) => {
    setProcessType(type);
    setIsOpenViewReport(true);
  }

  return (
    <Box sx={{ flexGrow: 1 }} className="p-l-25 p-r-25">
      <ViewReport 
        open={is_open_view_report}
        setOpen={setIsOpenViewReport}
        process_type={process_type}
      />
      <Grid container>
        <Grid item xs={3} md={3}>
          <Item onClick={() => onHandleCardClick('Production')} className='cursor card-hover'>
            <div>
              <p className='text-center m-0 p-0 APP-color fs-30'><b>{today_box_count_data?.total_box_count_at_production_room}</b></p>
              <p className='text-center p-16 gray'>Total Box Scanned At Production Room</p>
            </div>
          </Item>
        </Grid>
        <Grid item xs={3} md={3}>
          <Item onClick={() => onHandleCardClick('Warehouse')} className='cursor card-hover'>
            <div>
              <p className='text-center m-0 p-0 APP-color fs-30'><b>{today_box_count_data?.total_box_count_at_warehouse_room}</b></p>
              <p className='text-center p-16 gray'>Total Box Scanned At Warehouse Entry</p>
            </div>
          </Item>
        </Grid>
        <Grid item xs={3} md={3}>
          <Item onClick={() => onHandleCardClick('Dispatch')} className='cursor card-hover'>
            <div>
              <p className='text-center m-0 p-0 APP-color fs-30'><b>{today_box_count_data?.total_box_count_at_dispatch_room}</b></p>
              <p className='text-center p-16 gray'>Total Box Scanned At Dispatch Entry &nbsp;</p>
            </div>
          </Item>
        </Grid>
        <Grid item xs={3} md={3}>
          <Item onClick={() => onHandleCardClick('Missing Box')} className='cursor card-hover'>
            <div>
              <p className='text-center m-0 p-0 APP-color fs-30'><b>{today_box_count_data?.total_box_difference}</b></p>
              <p className='text-center p-16 gray'>Total Difference Count In Box Scanned</p>
            </div>
          </Item>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Home