import { Button } from '@mui/material'
import React from 'react'
import logo from '../assets/images/TrackSureNewLogo.png'

function PageNotFound() {
    return (
        <div className='w-100 d-flex justify-center align-center' style={{ height: '100vh' }}>
            <div className='d-flex flex-column align-items-center justify-center flex-direction-column'>
                <div>
                    <img
                        src={logo}
                        style={{ width: 400 }}
                    />
                </div>
                <h1 className='text-center'>Page Not Found</h1>
                <p className='text-center'>The page you are looking for does not exist.</p>
                <Button onClick={() => window.history.back()} variant="contained" sx={{ mt: 3 }}>Go Back</Button>
            </div>
        </div>
    )
}

export default PageNotFound