import { useEffect, useState } from 'react';
import { Box, IconButton, Modal, Table, Typography, TableBody, TableCell, TableHead, TableRow, } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { getDeliveryChallanDataByChallanId } from '../../utils/api.delivery.challan';
import { ShowIndianStandardTime } from '../../common/common';

const style = {
    position: 'absolute',
    top: '45%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    border: '2px solid aliceblue',
    boxShadow: 24,
    borderRadius: 2,
    p: 4,
};

function ProductDetailsOfDC({ open_model, setOpenModel, selected_row }) {
    const [delivery_challan_by_challan_id, set_delivery_challan_by_challan_id] = useState([])

    useEffect(() => {
        (async () => {
            if (selected_row?.delivery_challan_id) {
                const response = await getDeliveryChallanDataByChallanId(selected_row.delivery_challan_id)
                if (response.data.data) {
                    set_delivery_challan_by_challan_id(response.data.data)
                }
            }
        })()
    }, [selected_row.delivery_challan_id])

    return (
        <div>

            <Box>
                <Modal
                    open={open_model}
                    onClose={() => setOpenModel(false)}
                >
                    <Box sx={style}>
                        <Box className='w-100 bg-red'>
                            <Box className="d-flex justify-between">
                                <IconButton
                                    aria-label="close"
                                    onClick={() => setOpenModel(false)}
                                    sx={{
                                        position: 'absolute',
                                        top: 8,
                                        right: 8,
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                                <Box className="w-100 text-center">
                                    <Typography variant="h6" component="h1"><b>Challan Details</b></Typography>
                                </Box>
                            </Box>
                            <Box className="m-t-10">
                                <Typography variant="h6" component="h1">
                                    <p>Date: <b>{ShowIndianStandardTime(selected_row.date).date} {ShowIndianStandardTime(selected_row.date).time}</b></p>
                                </Typography>
                                <Typography variant="h6" component="h1">
                                    <p>Vehicle: <b>{selected_row.vehicle_no}</b></p>
                                </Typography>
                                <Typography variant="h6" component="h1">
                                    <p>Challan No: <b>{selected_row.challan_id}</b></p>
                                </Typography>
                            </Box>
                        </Box>
                        <Box>
                            {delivery_challan_by_challan_id.length && <Table className='b-5 m-t-15'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className='m-0 p-0 p-l-15 p-5 dark-blue'><strong>Product Name</strong></TableCell>
                                        <TableCell className='m-0 p-0 p-l-15 dark-blue'><strong>Sku Size</strong></TableCell>
                                        <TableCell className='m-0 p-0 p-l-15 dark-blue'><strong>Box Count</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {delivery_challan_by_challan_id?.map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell className='m-0 p-0 p-l-15 p-5'>{item.product}</TableCell>
                                            <TableCell className='m-0 p-0 p-l-15'>{item.sku_size}</TableCell>
                                            <TableCell className='m-0 p-0 p-l-15'>{item.box_count}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>}
                        </Box>
                    </Box>
                </Modal>
            </Box>
        </div>
    )
}

export default ProductDetailsOfDC