import { Box, Button, IconButton, Modal, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { checkValidPhoneNumber } from '../../common/common';
import { errorMessage, successMessage } from '../../common/Toast';
import { updateDriverData } from '../../utils/api.driver';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    bgcolor: 'background.paper',
    border: '2px solid aliceblue',
    boxShadow: 24,
    borderRadius: 2,
    p: 4,
};

function EditExistingDriver({ open_model, setOpenModel, driver_data, setDriverData, setSuccessResponse }) {
    const [name, setName] = useState('')
    const [vehicle_no, setVehicleNo] = useState('')
    const [phone_number, setPhoneNumber] = useState('')
    const [address, setAddress] = useState('')
    const [driver_id, setDriverId] = useState('')
    const [is_check_valid_number, setIsCheckValidNumber] = useState(true)

    useEffect(() => {
        console.log(driver_data, "&&&&&");
        setName(driver_data?.name)
        setVehicleNo(driver_data?.vehicle_no)
        setPhoneNumber(driver_data?.phone_no)
        setAddress(driver_data?.location)
        setDriverId(driver_data?.id)
    }, [driver_data])

    const handleClose = () => {
        setOpenModel(false)
        setDriverData('')
        clear()
    };

    const onHandleSubmit = async (event) => {
        event.preventDefault()
        const driver_data = {
            name: name,
            phone_no: phone_number,
            vehicle_no: vehicle_no,
            location: address
        }
        try {
            const update_user_response = await updateDriverData(driver_id, driver_data)
            if (update_user_response.status === 200) {
                setSuccessResponse(true)
                successMessage(update_user_response?.data?.message)
            }
            if (update_user_response?.response?.data?.error === 'Invalid User ID') {
                errorMessage('Phone number is already exist.')
            }
        } catch (error) {
            errorMessage(error?.response?.data?.message)
        } finally {
            clear()
        }
    }

    const onHandlePhoneNumber = (event) => {
        const phoneNo = event.target.value;
        if (checkValidPhoneNumber(phoneNo)) {
            setPhoneNumber(phoneNo);
            setIsCheckValidNumber(true);
        } else {
            setPhoneNumber(phoneNo);
            setIsCheckValidNumber(false);
        }
    }

    const clear = () => {
        setName('')
        setPhoneNumber('')
        setVehicleNo('')
        setAddress('')
        setOpenModel(false)
        setIsCheckValidNumber(true)
        setDriverData('')
    }

    return (
        <div>
            <Modal
                open={open_model}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <div>
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                                position: 'absolute',
                                top: 8,
                                right: 8,
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" component="h2">
                            <strong>Edit Existing Driver</strong>
                        </Typography>

                        <Box>
                            <div>
                                <form onSubmit={onHandleSubmit}>
                                    <div>
                                        <TextField
                                            required
                                            className='w-100'
                                            label="Name"
                                            type='text'
                                            value={name}
                                            style={{ border: '1px solid aliceblue', marginTop: '10px' }}
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                    </div>
                                    <div>
                                        <TextField
                                            required
                                            className='w-100'
                                            label="Phone Number"
                                            type='tel_no'
                                            value={phone_number}
                                            inputProps={{ maxLength: 13 }}
                                            onChange={(e) => onHandlePhoneNumber(e)}
                                            style={{ border: '1px solid aliceblue', marginTop: '10px' }}
                                        />
                                    </div>
                                    <div>
                                        <TextField
                                            required
                                            className='w-100'
                                            label="Vehicle Number"
                                            type='text'
                                            value={vehicle_no}
                                            style={{ border: '1px solid aliceblue', marginTop: '10px' }}
                                            onChange={(e) => setVehicleNo(e.target.value)}
                                        />
                                    </div>
                                    <div>
                                        <TextField
                                            required
                                            className='w-100'
                                            label="Address"
                                            type='text'
                                            value={address}
                                            style={{ border: '1px solid aliceblue', marginTop: '10px' }}
                                            onChange={(e) => setAddress(e.target.value)}
                                        />
                                    </div>
                                    <div>
                                        <p style={{ color: 'red' }}>{!is_check_valid_number ? 'Please enter valid 10 digit number start with +91.' : ''}</p>
                                    </div>

                                    <Box className='d-flex justify-end g-5 m-t-10'>
                                        <Button variant='outlined' onClick={handleClose} >Cancel</Button>
                                        <Button variant='outlined' disabled={!is_check_valid_number} type='submit'>Submit</Button>
                                    </Box>
                                </form>
                            </div>

                        </Box>

                    </div>
                </Box>
            </Modal>
        </div>
    )
}

export default EditExistingDriver