import { NavLink, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import SidebarMenu from "./SidebarMenu";
import routes from './Routes'
import logo from '../assets/images/tracksure.png'
import Header from "../header/Header";
import { logoutUser } from "../utils/api.authentication";

const SideBar = ({ children }) => {
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsOpen(false);
      } else {
        setIsOpen(true);
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const onLogoutHandle = async () => {
    await logoutUser()
    localStorage.clear()
    navigate('/login')
  }

  return (
    <>
      <div className="main-container">
        <div
          style={{ width: 250 }}
          className='sidebar'
        >
          <div className="sticky overflowx-hidden overflow-y h-100vh">
            <div className="top_section">
              <div className="text-center"style={{ width: "100% !important", transform: "scale(1)"}}>
                <img src={logo} height={180} width={350}/>
              </div>
            </div>

            <div className="d-flex space-between flex-direction-column bg-blue">
              <section className="routes">
                {routes.map((route, index) => {
                  if (route.subRoutes) {
                    return (
                      <SidebarMenu
                        setIsOpen={setIsOpen}
                        route={route}
                        isOpen={isOpen}
                      />
                    );
                  }

                  return (
                    <NavLink
                      to={route.path}
                      key={index}
                      className="link"
                    >
                      {/* <div className="icon">{route.icon}</div> */}
                      {isOpen && (
                        <div>
                          {route.name}
                        </div>
                      )}
                    </NavLink>
                  );
                })}
              </section>

              {!(window.innerWidth <= 768) && <div>
                <div className="align-right p-r-10 APP-color">
                  <div className="d-flex justify-between link" style={{ padding: '10px 20px' }}>
                    <p style={{ marginBottom: '5px', color: 'white' }} className="cursor" onClick={onLogoutHandle}>Logout</p>
                  </div>
                </div>
              </div>}
            </div>
          </div>
        </div>

        <main className="scrollbar APP-background" style={{ width: isOpen ? "85%" : "calc(100% - 50px)" }}>
          <div><Header /></div>
          {children}
        </main>
      </div>
    </>
  );
};

export default SideBar;
