import React, { useEffect, useRef, useState } from 'react'
import { Button, TextField } from '@mui/material'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import DeleteIcon from '@mui/icons-material/Delete';
import { useCompanyContext } from '../common/companyContext';
import { addLine, deleteLine, getLineList } from '../utils/api.common';
import AppConformationModel from '../widgets/AppConformationModel';
import { errorMessage, successMessage } from '../common/Toast';

function AddLines() {
    const gridRef = useRef();
    const [line_name, setLineName] = useState('')
    const { selectedCompany } = useCompanyContext();
    const [title, setTitle] = useState('')
    const [message, setMessage] = useState('')
    const [line_id, setLineId] = useState('')
    const [line_data, setLineData] = useState([])
    const [is_delete_conformation, setIsDeleteConformation] = useState(false)

    useEffect(() => {
        (async () => {
            getCompanyLineList()
        })()
    }, [selectedCompany])

    const getCompanyLineList = async () => {
        try {
            const response = await getLineList(selectedCompany)
            response?.data?.data && setLineData(response?.data?.data)
        } catch (error) {
            console.error(error);
        }
    }

    const addNewLine = async () => {
        try {
            const line_data = { line_name: line_name }
            const response = await addLine(line_data)
            if (response.status === 200) {
                successMessage(response.data.message)
                getCompanyLineList()
                setLineName('')
            }
        } catch (error) {
            errorMessage(error.response.data.error)
            console.error(error);
        }
    }

    const columnDefs = [
        {
            headerName: "S No.",
            valueGetter: "node.rowIndex + 1",
            width: '140',
            sortable: true
        },
        {
            headerName: 'Line Name',
            field: 'line',
            width: '400',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true,
            filter: true
        },
        {
            headerName: 'Company ID',
            field: 'company_id',
            width: '400',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true,
            filter: true
        },
        {
            headerName: 'Delete',
            width: '300',
            cellStyle: { borderLeft: '1px solid lightgray' },
            cellRenderer: (params) => (
                <div className='d-flex g-5'>
                    <div className='w-50'>
                        <DeleteIcon className='cursor APP-color'
                            onClick={() => onDeleteConformation(params)}
                        />
                    </div>
                </div>
            ),
            headerClass: 'header-border'
        },
    ]

    const onDeleteConformation = (row_data) => {
        setLineId(row_data.data.id)
        setTitle(`Delete Line (${row_data.data.line})`)
        setMessage(`Do you want delete this line ?`)
        setIsDeleteConformation(true)
    }

    const onDeleteLine = async () => {
        try {
            const delete_response = await deleteLine(line_id)
            if (delete_response?.status === 200) {
                successMessage(delete_response?.data?.message)
                getCompanyLineList()
            }
        } catch (error) {
            errorMessage(error?.response?.data?.message)
        } finally {
            setLineId('')
        }
    }

    return (
        <div>
            <AppConformationModel
                open={is_delete_conformation}
                setOpen={setIsDeleteConformation}
                title={title}
                message={message}
                onConformAction={onDeleteLine}
            />
            <main className='m-a-20'>
                <div style={{ fontSize: 20 }}>
                    <h4>Add Line</h4>
                    <h5 className='gray m-t-10'>Line name</h5>
                    <div>
                        <TextField
                            className='w-100 m-t-10'
                            onChange={(e) => setLineName(e.target.value)}
                            placeholder='Enter Line Name'
                            variant="standard"
                            value={line_name}
                        />
                    </div>
                    <div className='d-flex justify-center m-t-10'>
                        <Button variant="contained" disabled={!line_name} onClick={addNewLine}>Add Line</Button>
                    </div>
                </div>

                <h3 className='m-t-10'>Lines Table</h3>
                <div
                    className="ag-theme-alpine m-t-20">
                    <AgGridReact
                        ref={gridRef}
                        rowData={line_data}
                        columnDefs={columnDefs}
                        pagination={true}
                        domLayout={'autoHeight'}
                        paginationPageSize={10}
                        paginationPageSizeSelector={[10, 25, 50]}
                    />
                </div>
            </main>
        </div>
    )
}

export default AddLines