import React, { useEffect, useRef, useState, } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Box, Button } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import AppConformationModel from '../../widgets/AppConformationModel';
import { errorMessage, successMessage } from '../../common/Toast';
import { useCompanyContext } from '../../common/companyContext';
import { deleteDriver, getAllDriver } from '../../utils/api.driver';
import AddNewDriver from './AddNewDriver';
import EditExistingDriver from './EditExistingDriver';

const ManageDrivers = () => {
    const gridRef = useRef();
    const { selectedCompany } = useCompanyContext();
    const [is_add_new_driver, setIsAddNewDriver] = useState(false)
    const [rowData, setRowData] = useState([]);
    const [title, setTitle] = useState('')
    const [message, setMessage] = useState('')
    const [driver_id, setDriverId] = useState('')
    const [is_delete_conformation, setIsDeleteConformation] = useState(false)
    const [driver_data_for_edit, setDriverDataForEdit] = useState('')
    const [is_edit_conformation, setIsEditConformation] = useState(false)
    const [is_success_response, setIsSuccessResponse] = useState(false)

    useEffect(() => {
        (async () => {
            getAllDriversList()
        })()
    }, [selectedCompany])

    useEffect(() => {
        (async () => {
            if (is_success_response === true) {
                getAllDriversList()
                setIsSuccessResponse(false)
            }
        })()
    }, [is_success_response])

    const getAllDriversList = async () => {
        try {
            const response = await getAllDriver()
            response?.data?.data && setRowData(response?.data?.data)
        } catch (error) {
            console.error(error);
        }
    }

    const onDeleteConformation = (row_data) => {
        setDriverId(row_data.data.id)
        setTitle(`Delete Driver (${row_data.data.name})`)
        setMessage(`Do you want delete this driver ?`)
        setIsDeleteConformation(true)
    }

    const onDeleteUser = async () => {
        try {
            const delete_response = await deleteDriver(driver_id)
            if (delete_response?.status === 200) {
                successMessage(delete_response?.data?.message)
                getAllDriversList()
            }
        } catch (error) {
            errorMessage(error?.response?.data?.message)
        } finally {
            setDriverId('')
        }
    }

    const onEditConformation = (row_data) => {
        setDriverId(row_data.data.id)
        setIsEditConformation(true)
        setDriverDataForEdit(row_data.data)
    }

    const columnDefs = [
        {
            headerName: "S No.",
            valueGetter: "node.rowIndex + 1",
            width: 78,
            sortable: true
        },
        {
            headerName: 'Name',
            field: 'name',
            width: 300,
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true,
            filter: true
        },
        {
            headerName: 'Phone No',
            width: 340,
            field: 'phone_no',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true,
            filter: true
        },
        {
            headerName: 'Vehicle',
            width: 340,
            field: 'vehicle_no',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true,
            filter: true
        },
        {
            headerName: 'Address',
            width: 340,
            field: 'location',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true,
            filter: true
        },
        {
            headerName: 'Edit',
            cellStyle: { borderLeft: '1px solid lightgray' },
            width: '150',
            cellRenderer: (params) => (
                <div className='d-flex g-5'>
                    <div className='w-50' onClick={() => onEditConformation(params)}><EditIcon className='cursor APP-color' /></div>
                </div>
            ),
            headerClass: 'header-border'
        },
        {
            headerName: 'Delete',
            cellStyle: { borderLeft: '1px solid lightgray' },
            width: '150',
            cellRenderer: (params) => (
                <div className='d-flex g-5'>
                    <div className='w-50'>
                        <DeleteIcon className='cursor APP-color'
                            onClick={() => onDeleteConformation(params)}
                        />
                    </div>
                </div>
            ),
            headerClass: 'header-border'
        },
    ]

    return (
        <Box style={{ width: '100%', height: '50%' }}>

            <Box>
                <AppConformationModel
                    open={is_delete_conformation}
                    setOpen={setIsDeleteConformation}
                    title={title}
                    message={message}
                    onConformAction={onDeleteUser}
                />

                <AddNewDriver
                    open_model={is_add_new_driver}
                    setOpenModel={setIsAddNewDriver}
                    setSuccessResponse={setIsSuccessResponse}
                />

                <EditExistingDriver
                    open_model={is_edit_conformation}
                    setOpenModel={setIsEditConformation}
                    driver_data={driver_data_for_edit}
                    setDriverData={setDriverDataForEdit}
                    setSuccessResponse={setIsSuccessResponse}
                />
            </Box>

            <main className="m-a-20">
                <div>
                    <div className='d-flex justify-end'>
                        <div className='d-flex justify-between'>
                            {/* <Button className='black' startIcon={<DownloadIcon />}>Download Report in CSV</Button> */}
                            <Button className='black' startIcon={<AddIcon />} onClick={() => setIsAddNewDriver(true)}>Add</Button>
                        </div>
                    </div>
                </div>

                <div
                    style={{ height: '100%', width: '100%' }}
                    className="ag-theme-alpine m-t-20">
                    <AgGridReact
                        ref={gridRef}
                        rowData={rowData}
                        columnDefs={columnDefs}
                        pagination={true}
                        domLayout={'autoHeight'}
                        paginationPageSize={10}
                        paginationPageSizeSelector={[10, 25, 50]}
                    />
                </div>
            </main>
        </Box>
    );
};

export default ManageDrivers