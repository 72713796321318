import { Box, Button, IconButton, Modal, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { checkValidPhoneNumber } from '../../common/common';
import { errorMessage, successMessage } from '../../common/Toast';
import { updateUserData } from '../../utils/api.users';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    bgcolor: 'background.paper',
    border: '2px solid aliceblue',
    boxShadow: 24,
    borderRadius: 2,
    p: 4,
};

function EditExistingUser({ open_model, setOpenModel, user_data, setUserData, setSuccessResponse }) {
    const [name, setName] = useState('')
    const [role, setRole] = useState('')
    const [phone_number, setPhoneNumber] = useState('')
    const [user_id, setUserId] = useState('')
    const [is_check_valid_number, setIsCheckValidNumber] = useState(true)

    useEffect(() => {
        setName(user_data?.name)
        setRole(user_data?.role)
        setPhoneNumber(user_data?.phone_no)
        setUserId(user_data?.id)
    }, [user_data])

    const handleClose = () => {
        setOpenModel(false)
        setUserData('')
        clear()
    };

    const onHandleSubmit = async (event) => {
        event.preventDefault()
        const user_data = {
            name: name,
            phone_no: phone_number,
            role: role
        }
        try {
            const update_user_response = await updateUserData(user_id, user_data)
            if (update_user_response.status === 200) {
                setSuccessResponse(true)
                successMessage(update_user_response?.data?.message)
            }
            if (update_user_response?.response?.data?.error === 'Invalid User ID') {
                errorMessage('Phone number is already exist.')
            }
        } catch (error) {
            errorMessage(error?.response?.data?.message)
        } finally {
            clear()
        }
    }

    const onHandlePhoneNumber = (event) => {
        const phoneNo = event.target.value;
        if (checkValidPhoneNumber(phoneNo)) {
            setPhoneNumber(phoneNo);
            setIsCheckValidNumber(true);
        } else {
            setPhoneNumber(phoneNo);
            setIsCheckValidNumber(false);
        }
    }

    const clear = () => {
        setName('')
        setPhoneNumber('')
        setRole('')
        setOpenModel(false)
        setIsCheckValidNumber(true)
        setUserData('')
    }

    return (
        <div>
            <Modal
                open={open_model}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <div>
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                                position: 'absolute',
                                top: 8,
                                right: 8,
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" component="h2">
                            <strong>Edit Existing User</strong>
                        </Typography>

                        <Box>
                            <div>
                                <form onSubmit={onHandleSubmit}>
                                    <div>
                                        <TextField
                                            required
                                            className='w-100'
                                            label="Name"
                                            type='text'
                                            value={name}
                                            style={{ border: '1px solid aliceblue', marginTop: '10px' }}
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                    </div>
                                    <div>
                                        <select
                                            className='w-100 m-t-10 p-16'
                                            required
                                            onChange={(e) => setRole(e.target.value)}
                                            value={role}
                                        >
                                            <option value={''}>Select Role</option>
                                            <option value={'super_admin'}>Super Admin</option>
                                            <option value={'admin'}>Admin</option>
                                            <option value={'incharge'}>Incharge</option>
                                        </select>
                                    </div>
                                    <div>
                                        <TextField
                                            required
                                            className='w-100'
                                            label="Phone Number"
                                            type='tel_no'
                                            value={phone_number}
                                            inputProps={{ maxLength: 13 }}
                                            onChange={(e) => onHandlePhoneNumber(e)}
                                            style={{ border: '1px solid aliceblue', marginTop: '10px' }}
                                        />
                                    </div>
                                    <div>
                                        <p style={{ color: 'red' }}>{!is_check_valid_number ? 'Please enter valid 10 digit number start with +91.' : ''}</p>
                                    </div>

                                    <Box className='d-flex justify-end g-5 m-t-10'>
                                        <Button variant='outlined' onClick={handleClose} >Cancel</Button>
                                        <Button variant='outlined' disabled={!is_check_valid_number} type='submit'>Submit</Button>
                                    </Box>
                                </form>
                            </div>

                        </Box>

                    </div>
                </Box>
            </Modal>
        </div>
    )
}

export default EditExistingUser