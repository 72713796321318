import React from 'react'
import { Button, TextField } from '@mui/material'

function AddManualData() {
    return (
        <div>
            <div style={{ margin: 20, fontSize: 20 }}>
                <h4>Add Manual Data</h4>
                <br></br>
                <h5 style={{ color: 'gray' }}>Product Name</h5>
                <br></br>
                <div style={{ width: '100%', display: 'flex' }}>
                    <TextField style={{ width: '100%', display: 'flex' }} id="standard-basic" label="Enter Product Name" variant="outlined" />
                </div>
                <br></br>
                <h5 style={{ color: 'gray' }}>Net Weight</h5>
                <br></br>
                <div style={{ width: '100%', display: 'flex' }}>
                    <TextField style={{ width: '100%', display: 'flex' }} id="standard-basic" label="Enter Net Weight" variant="outlined" />
                </div>
                <br></br>
                <h5 style={{ color: 'gray' }}>Detection Time</h5>
                <br></br>
                <div style={{ width: '100%', display: 'flex' }}>
                    <TextField style={{ width: '100%', display: 'flex' }} id="standard-basic" label="Enter Detection Time" variant="outlined" />
                </div>
                <br></br>
                <h5 style={{ color: 'gray' }}>Product Count</h5>
                <br></br>
                <div style={{ width: '100%', display: 'flex' }}>
                    <TextField style={{ width: '100%', display: 'flex' }} id="standard-basic" label="Enter Product Count" variant="outlined" />
                </div>
                <br></br>
                <div className='d-flex justify-center'>
                    <Button variant="contained">Add Data</Button>
                </div>

            </div>
        </div>
    )
}

export default AddManualData