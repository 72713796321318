import React, { useEffect, useRef, useState } from 'react'
import { Button, TextField } from '@mui/material'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import DeleteIcon from '@mui/icons-material/Delete';
import { errorMessage, successMessage } from '../common/Toast';
import { addProduct, deleteProduct, getProductList } from '../utils/api.common';
import { useCompanyContext } from '../common/companyContext';
import AppConformationModel from '../widgets/AppConformationModel';

function AddProduct() {
    const gridRef = useRef();
    const { selectedCompany } = useCompanyContext();
    const [product_name, setProductName] = useState('')
    const [sap_code, setSapCode] = useState('')
    const [sku_size, setSkuSize] = useState('')
    const [product_data, setProductData] = useState([])
    const [product_id, setProductId] = useState('')
    const [title, setTitle] = useState('')
    const [message, setMessage] = useState('')
    const [is_delete_conformation, setIsDeleteConformation] = useState(false)

    useEffect(() => {
        (async () => {
            getCompanyProductList()
        })()
    }, [selectedCompany])

    const getCompanyProductList = async () => {
        try {
            const response = await getProductList(selectedCompany)
            response?.data?.data && setProductData(response?.data?.data)
        } catch (error) {
            console.error(error);
        }
    }

    const addNewProduct = async () => {
        try {
            if (!product_name || !sap_code || !sku_size) {
                errorMessage('Please fill all the fields')
                return
            }
            const data = {
                product_name,
                sap_code,
                sku_size
            }
            const response = await addProduct(data)
            if (response.status === 200) {
                successMessage(response.data.message)
                getCompanyProductList()
                setProductName('')
                setSapCode('')
                setSkuSize('')
            }
        } catch (error) {
            errorMessage(error.response.data.error)
            console.error(error);
        }
    }

    const onDeleteConformation = (row_data) => {
        setProductId(row_data.data.id)
        setTitle(`Delete Line (${row_data.data.product_name})`)
        setMessage(`Do you want delete this product ?`)
        setIsDeleteConformation(true)
    }

    const onDeleteLine = async () => {
        try {
            const delete_response = await deleteProduct(product_id)
            if (delete_response?.status === 200) {
                successMessage(delete_response?.data?.message)
                getCompanyProductList()
            }
        } catch (error) {
            errorMessage(error?.response?.data?.message)
        } finally {
            setProductId('')
        }
    }

    const columnDefs = [
        {
            headerName: 'Product Name',
            field: 'product_name',
            width: '400',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true,
            filter: true
        },
        {
            headerName: 'Product ID',
            field: 'id',
            width: '150',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true,
            filter: true
        },
        {
            headerName: 'SAP Code',
            field: 'sap_code',
            width: '200',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true,
            filter: true
        },
        {
            headerName: 'SKU Size',
            field: 'sku_size',
            width: '250',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true,
            filter: true
        },
        {
            headerName: 'Company ID',
            field: 'company_id',
            width: '150',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true,
            filter: true
        },
        {
            headerName: 'Delete',
            width: '100',
            cellStyle: { borderLeft: '1px solid lightgray' },
            cellRenderer: (params) => (
                <div className='d-flex g-5'>
                    <div className='w-50'>
                        <DeleteIcon className='cursor APP-color'
                            onClick={() => onDeleteConformation(params)}
                        />
                    </div>
                </div>
            ),
            headerClass: 'header-border'
        },
    ]

    return (
        <div>
            <AppConformationModel
                open={is_delete_conformation}
                setOpen={setIsDeleteConformation}
                title={title}
                message={message}
                onConformAction={onDeleteLine}
            />
            <main className='m-a-20'>
                <div style={{ fontSize: 20 }}>
                    <h4>Add Product</h4>
                    <h5 className='gray m-t-20'>Product Name</h5>
                    <div>
                        <TextField
                            className='w-100 m-t-10'
                            onChange={(e) => setProductName(e.target.value)}
                            placeholder='eg. Masti Dahi'
                            variant="standard"
                            value={product_name}
                        />
                    </div>
                    <h5 className='gray m-t-20'>SAP Code</h5>
                    <div>
                        <TextField
                            className='w-100 m-t-10'
                            onChange={(e) => setSapCode(e.target.value)}
                            placeholder='eg. IMFCP01'
                            variant="standard"
                            value={sap_code}
                        />
                    </div>
                    <h5 className='gray m-t-20'>SKU Size</h5>
                    <div>
                        <TextField
                            className='w-100 m-t-10'
                            onChange={(e) => setSkuSize(e.target.value)}
                            placeholder='eg. 2 Pieces X 420 Pieces x 10g'
                            variant="standard"
                            value={sku_size}
                        />
                    </div>

                    <div className='d-flex justify-center m-t-10'>
                        <Button variant="contained" disabled={!product_name} onClick={addNewProduct}>Add New Product</Button>
                    </div>
                </div>

                <h3 className='m-t-10'>Product Table</h3>
                <div
                    className="ag-theme-alpine m-t-20">
                    <AgGridReact
                        ref={gridRef}
                        rowData={product_data}
                        columnDefs={columnDefs}
                        pagination={true}
                        domLayout={'autoHeight'}
                        paginationPageSize={10}
                        paginationPageSizeSelector={[10, 25, 50]}
                    />
                </div>
            </main>
        </div>
    )
}

export default AddProduct