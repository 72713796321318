import api from "./interceptor"

export const getAllDriver = async () => {
    try {
        const response = await api.get(`/drivers/v1/get_drivers`, {
            params: { company_name: localStorage.getItem('company_name') }
        });
        return response;
    } catch (error) {
        throw error;
    }
}

export const AddNewDriverData = async (driver_data) => {
    try {
        const company_name = localStorage.getItem('company_name');
        const response = await api.post(`/drivers/v1/add_drivers`, driver_data, {params: { company_name: company_name }});
        return response;
    } catch (error) {
        throw error;
    }
}

export const updateDriverData = async (driver_id, driver_data) => {
    try {
        const company_name = localStorage.getItem('company_name');
        const response = await api.post(`/drivers/v1/update_drivers?company_name=${company_name}&driver_id=${driver_id}`, driver_data);
        return response;
    } catch (error) {
        throw error;
    }
}

export const deleteDriver = async (driver_id) => {
    try {
        const company_name = localStorage.getItem('company_name');
        const response = await api.post(`/drivers/v1/delete_drivers?company_name=${company_name}&driver_id=${driver_id}`);
        return response;
    } catch (error) {
        throw error;
    }
}