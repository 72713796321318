import React, { useEffect, useRef, useState } from 'react'
import { Button, TextField } from '@mui/material'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import DeleteIcon from '@mui/icons-material/Delete';
import { useCompanyContext } from '../common/companyContext';
import { addShift, deleteShift, getShiftList } from '../utils/api.common';
import AppConformationModel from '../widgets/AppConformationModel';
import { errorMessage, successMessage } from '../common/Toast';

function AddShifts() {
    const gridRef = useRef();
    const { selectedCompany } = useCompanyContext();
    const [shift_name, setShiftName] = useState('')
    const [lower_shift_limit, setLowerShiftLimit] = useState('')
    const [upper_shift_limit, setUpperShiftLimit] = useState('')
    const [title, setTitle] = useState('')
    const [message, setMessage] = useState('')
    const [shift_id, setShiftId] = useState('')
    const [shift_list_data, setShiftListData] = useState([])
    const [is_delete_conformation, setIsDeleteConformation] = useState(false)

    useEffect(() => {
        (async () => {
            getCompanyShiftList()
        })()
    }, [selectedCompany])

    const getCompanyShiftList = async () => {
        try {
            const response = await getShiftList(selectedCompany)
            response?.data?.data && setShiftListData(response?.data?.data)
        } catch (error) {
            console.error(error);
        }
    }

    const addNewShift = async () => {
        try {
            const shift_data = {
                shift_name: shift_name,
                upper_time_limit: `${upper_shift_limit}:59`,
                lower_time_limit: `${lower_shift_limit}:00`,
            }
            const response = await addShift(shift_data)
            if (response.status === 200) {
                successMessage(response.data.message)
                getCompanyShiftList()
                setShiftName('')
                setLowerShiftLimit('')
                setUpperShiftLimit('')
            }
        } catch (error) {
            errorMessage(error.response.data.error)
            console.error(error);
        }
    }

    const columnDefs = [
        {
            headerName: "S No.",
            valueGetter: "node.rowIndex + 1",
            width: '100',
            sortable: true
        },
        {
            headerName: 'Shift Name',
            field: 'shift_name',
            width: '300',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true,
            filter: true
        },
        {
            headerName: 'Lower Shift Limit',
            field: 'lower_limit',
            width: '300',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true,
            filter: true
        },
        {
            headerName: 'Upper Shift Limit',
            field: 'upper_limit',
            width: '300',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true,
            filter: true
        },
        {
            headerName: 'Company ID',
            field: 'company_id',
            width: '130',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true,
            filter: true
        },
        {
            headerName: 'Delete',
            width: '120',
            cellStyle: { borderLeft: '1px solid lightgray' },
            cellRenderer: (params) => (
                <div className='d-flex g-5'>
                    <div className='w-50'>
                        <DeleteIcon className='cursor APP-color'
                            onClick={() => onDeleteConformation(params)}
                        />
                    </div>
                </div>
            ),
            headerClass: 'header-border'
        },
    ]

    const onDeleteConformation = (row_data) => {
        setShiftId(row_data.data.id)
        setTitle(`Delete Line (${row_data.data.shift_name})`)
        setMessage(`Do you want delete this shift ?`)
        setIsDeleteConformation(true)
    }

    const onDeleteLine = async () => {
        try {
            const delete_response = await deleteShift(shift_id)
            if (delete_response?.status === 200) {
                successMessage(delete_response?.data?.message)
                getCompanyShiftList()
            }
        } catch (error) {
            errorMessage(error?.response?.data?.message)
        } finally {
            setShiftId('')
        }
    }

    return (
        <div>
            <AppConformationModel
                open={is_delete_conformation}
                setOpen={setIsDeleteConformation}
                title={title}
                message={message}
                onConformAction={onDeleteLine}
            />
            <main className='m-a-20'>
                <div style={{ fontSize: 20 }}>
                    <h4>Add Shift</h4>
                    <h5 className='gray m-t-10'>Shift name</h5>
                    <div>
                        <TextField
                            className='w-100 m-t-10'
                            onChange={(e) => setShiftName(e.target.value)}
                            placeholder='Enter Shift Name'
                            variant="standard"
                            value={shift_name}
                        />
                    </div>
                    <div>
                        <h5 className='gray m-t-10'>Lower Time Limit:</h5>
                        <div>
                            <TextField
                                className='w-100 m-t-10'
                                onChange={(e) => setLowerShiftLimit(e.target.value)}
                                placeholder='Lower Shift Limit'
                                variant="standard"
                                type='time'
                                value={lower_shift_limit}
                            />
                        </div>
                    </div>
                    <div>
                        <h5 className='gray m-t-10'>Upper Time Limit:</h5>
                        <div>
                            <TextField
                                className='w-100 m-t-10'
                                onChange={(e) => setUpperShiftLimit(e.target.value)}
                                placeholder='Upper Shift Limit'
                                variant="standard"
                                type='time'
                                value={upper_shift_limit}
                            />
                        </div>
                    </div>

                    <div className='d-flex justify-center m-t-10'>
                        <Button variant="contained" disabled={!shift_name} onClick={addNewShift}>Add Shift</Button>
                    </div>
                </div>

                <h3 className='m-t-10'>Shift Table</h3>
                <div
                    className="ag-theme-alpine m-t-20">
                    <AgGridReact
                        ref={gridRef}
                        rowData={shift_list_data}
                        columnDefs={columnDefs}
                        pagination={true}
                        domLayout={'autoHeight'}
                        paginationPageSize={10}
                        paginationPageSizeSelector={[10, 25, 50]}
                    />
                </div>
            </main>
        </div>
    )
}

export default AddShifts