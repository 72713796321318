import api from './interceptor'

export const registerUser = async (register_data) => {
    try {
        const response = await api.post(`/auth/v1/register`, register_data);
        return response;
    } catch (error) {
        throw error;
    }
}

export const loginUser = async (register_data) => {
    try {
        const response = await api.post(`/auth/v1/login`, register_data);
        return response;
    } catch (error) {
        throw error;
    }
}

export const logoutUser = async () => {
    try {
        const response = await api.post(`auth/v1/revoke-token`);
        return response;
    } catch (error) {
        throw error;
    }
}