import { Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { addCompany, getCompanyList } from '../utils/api.common'
import { errorMessage, successMessage } from '../common/Toast'

function AddCompany() {
    const [company_list, setCompanyList] = useState([])
    const [company_type, setCompanyType] = useState('parent')
    const [company_name, setCompanyName] = useState('')
    const [parent_company, setParentCompany] = useState('')

    useEffect(() => {
        (async () => {
            const response = await getCompanyList()
            response?.data?.data && setCompanyList(response?.data?.data)
        })()
    }, [])

    const addNewCompany = async(e) => {
        e.preventDefault()
        try {
            const data = {
                company_type,
                company_name,
                parent_company
            }
            const response = await addCompany(data)
            if (response.status === 200) {
                successMessage(response.data.message)
                setCompanyType('parent')
                setCompanyName('')
                setParentCompany('')
            } else {
                errorMessage(response.response.data.error)
            }
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div>
            <form onSubmit={addNewCompany} className='m-a-20'>
                <div style={{ fontSize: 20 }}>
                    <h4>Add Company</h4>
                    <h5 className='gray m-t-10'>Company Type</h5>
                    <div>
                        <select className='w-100 p-5 m-t-10' onChange={(e) => setCompanyType(e.target.value.toLowerCase())} required>
                            <option selected={company_type === 'parent'}>parent</option>
                            <option selected={company_type === 'child'}>child</option>
                        </select>
                    </div>
                    <div>
                        <h5 className='gray m-t-10'>Company Name:</h5>
                        <div>
                            <input
                                value={company_name}
                                className='w-100 m-t-10 p-5'
                                placeholder='Company Name'
                                required
                                onChange={(e) => setCompanyName(e.target.value)}
                            />
                        </div>
                    </div>
                    <div>
                        <h5 className='gray m-t-10'>Parent Company:</h5>
                        <div>
                            <select className='w-100 p-5' disabled={company_type === 'parent'} onChange={(e) => setParentCompany(e.target.value.toLowerCase())} required>
                                <option>select</option>
                                {company_list && company_list.map((company, index) => (
                                    <option key={index} selected={company === parent_company} value={company}>{company}</option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className='d-flex justify-center m-t-10'>
                        <Button variant="contained" type='submit'>Add Company</Button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default AddCompany