import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "../views/Login";
import Dashboard from "./Dashboard";
import { CompanyProvider } from "../common/companyContext";
import { ToastContainer } from "react-toastify";
import PageNotFound from "../views/PageNotFound";
import ProtectedRoute from "./ProtectedRoute";

function AppRoute() {
    return (
        <Router>
            <CompanyProvider>
                <ToastContainer />
                <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path="/dashboard/*" element={
                        <ProtectedRoute>
                            <Dashboard />
                        </ProtectedRoute>
                    } />
                    <Route path="/login" element={<Login />} />
                    <Route path="*" element={<PageNotFound />} />
                </Routes>
            </CompanyProvider>
        </Router>
    );
}

export default AppRoute;
