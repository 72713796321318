import React, { useEffect, useState } from 'react'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { trackerColumn } from './ConstantTables';

function Tracker() {
    const tracker_column = trackerColumn()
    return (
        <div>
            <div
                style={{ height: '70%', margin: 10 }}
                className="ag-theme-alpine">
                <AgGridReact
                    rowData={[]}
                    columnDefs={tracker_column}
                    pagination={true}
                    domLayout={'autoHeight'}
                    paginationPageSize={8}
                />
            </div>
        </div>
    )
}

export default Tracker