export const startTimeDateFormate = (date) => {
    const [start_day, start_month, start_year] = date.split('/');
    const start_date = new Date(start_year, start_month - 1, start_day);
    start_date.setHours(0, 0, 0, 0);
    start_date.setMinutes(start_date.getMinutes() + 5*60 + 30);
    return start_date.toISOString()
}

export const endTimeDateFormate = (date) => {
    const [start_day, start_month, start_year] = date.split('/');
    const end_date = new Date(start_year, start_month - 1, start_day);
    end_date.setHours(23, 59, 59, 999);
    end_date.setMinutes(end_date.getMinutes() + 5*60 + 30);
    return end_date.toISOString()
}
